<template>
  <div
    class="config"
    :class="[
      { config_disabled: disabled },
      `config_${theme}`
    ]"
  >
    <div class="config__key-wrap">
      <BaseCheckbox
        v-if="checked !== undefined"
        :label="title"
        :modelValue="checked"
        :disabled="disabled"
        :theme="theme"
        @update:modelValue="onCheck"
      />
      <div
        v-else
        class="title"
      >
        <BaseIcon
          :icon="icon"
          class="title__icon"
        />
        <p>{{ title }}</p>
      </div>
    </div>
    <div class="config__slider-wrap">
      <BaseInputRange
        v-model="current"
        :min="min"
        :max="max"
        :step="step"
        :theme="theme"
        :disabled="disabled || panelDisabled"
      />
    </div>
    <div class="config__select-wrap">
      <BaseSelect
        v-model="current"
        :options="options"
        :theme="theme"
        :disabled="disabled || panelDisabled"
      />
    </div>
  </div>
</template>

<script>
import BaseCheckbox from '@/components/Base/BaseCheckbox'
import BaseInputRange from '@/components/Base/BaseInputRange'
import BaseSelect from '@/components/Base/BaseSelect'
import BaseIcon from '@/components/Base/BaseIcon'

export default {
  name: 'ConfigRow',
  components: {
    BaseIcon,
    BaseCheckbox,
    BaseInputRange,
    BaseSelect
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: undefined
    },
    icon: {
      type: String,
      default: null
    },
    modelValue: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      current: this.modelValue
    }
  },
  computed: {
    options () {
      const result = []

      for (let i = this.min; i <= this.max; i += this.step) {
        result.push({
          value: i,
          text: i + ' Гб.'
        })
      }

      return result
    },
    panelDisabled () {
      if (this.disabled) {
        return this.disabled
      } else if (this.checked === undefined || this.checked === true) {
        return false
      }

      return true
    }
  },
  watch: {
    current (val) {
      this.$emit('update:modelValue', +val)
    }
  },
  methods: {
    onCheck (val) {
      this.$emit('update:checked', val)
    }
  }
}
</script>

<style lang="scss" scoped>
$primary-color:                           #3EBBEA !default;
$disabled-color:                          #ABABAB !default;
$dark-color:                              #333333 !default;
$media-lg:                                1200px !default;
$media-md:                                992px !default;
$media-sm:                                768px !default;
$media-esm:                               576px !default;

.config {
  display: grid;
  grid-template-columns: 100px 1fr 120px;
  grid-gap: 12px;
  align-items: center;
  padding: 8px 0;

  @media screen and (max-width: $media-sm) {
    grid-template-columns: repeat(5, 1fr);
  }

  &_disabled {
    .title {
      color: $disabled-color !important;

      &__icon {
        color: inherit !important;
      }
    }
  }

  &_primary {
    .title {
      &__icon {
        color: $primary-color;
      }
    }
  }

  &_gold {
    .title {
      &__icon {
        color: $dark-color;
      }
    }
  }

  &__key-wrap {
    @media screen and (max-width: $media-sm) {
      grid-column: 1 / 2;
    }
    @media screen and (max-width: $media-esm) {
      grid-column: 1 / 3;
    }

    .label, .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__icon {
        width: 32px;
      }

      p {
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  &__slider-wrap {
    @media screen and (max-width: $media-sm) {
      grid-row: 2 / 3;
      grid-column: 1 / 6;
    }
  }
  &__select-wrap {
    @media screen and (max-width: $media-sm) {
      grid-column: 5 / 6;
    }
    @media screen and (max-width: $media-esm) {
      grid-column: 4 / 6;
    }
  }
}
</style>
