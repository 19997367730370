<template>
  <div class="wrapper">
    <div
      v-click-outside="onClose"
      class="select"
      :class="[
        { select_disabled: disabled },
        `select_${theme}`
      ]"
    >
      <div
        class="preview"
        :class="previewClasses"
        @click="onToggle"
      >
      <span>
        {{ computedValue }}
      </span>

        <div class="wrap">
          <BaseIcon
            icon="select-arrow"
            class="icon"
          />
        </div>
      </div>

      <div
        v-if="open"
        class="options"
      >
        <div
          v-for="(option, index) of options"
          :key="index"
          class="option"
          :class="{ option_selected: option.value === modelValue }"
          @click="onChange(option)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clickOutside from '@/directives/clickOutside'
import BaseIcon from '@/components/Base/BaseIcon'

export default {
  name: 'BaseSelect',
  components: {
    BaseIcon
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    modelValue: {},
    size: {
      type: String,
      default: 'md',
      validator: val => ['sm', 'md', 'lg'].includes(val)
    },
    theme: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'gold'].includes(val)
    }
  },
  emits: {
    'update:modelValue': null
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      localValue: this.modelValue,
      open: false
    }
  },
  computed: {
    computedValue () {
      const curOption = this.options.find(item => item.value === this.localValue)
      if (curOption) {
        return curOption.text
      } else {
        return 'Выберите значение'
      }
    },
    previewClasses () {
      return [
        this.size
      ]
    }
  },
  watch: {
    modelValue (e) {
      this.localValue = e
    }
  },
  methods: {
    onChange (option) {
      this.localValue = option.value
      this.$emit('update:modelValue', option.value)
      this.open = false
    },
    onToggle () {
      this.open = !this.open
    },
    onClose () {
      this.open = false
    },
    onOpen () {}
  }
}
</script>

<style lang="scss" scoped>
$input-size-sm:                            40px !default;
$input-size-md:                            50px !default;
$input-size-lg:                            60px !default;
$primary-color:                           #3EBBEA !default;
$disabled-color:                          #ABABAB !default;
$dark-color:                              #333333 !default;

.select {
  position: relative;
  user-select: none;

  &_disabled {
    pointer-events: none !important;
    color: $disabled-color !important;
  }

  &_primary {
    color: $primary-color;

    .options {
      .option {
        &_selected {
          background: rgba(62, 187, 234, 0.5);
          color: #fff;
        }

        &:hover {
          background: #ececec;
          color: $primary-color;
        }
      }
    }
  }
  &_gold {
    color: $dark-color;

    .options {
      .option {
        &_selected {
          background: rgba(51, 51, 51, 0.5);
          color: #fff;
        }

        &:hover {
          background: #ececec;
          color: $dark-color;
        }
      }
    }
  }

  .preview {
    position: relative;
    width: 100%;
    min-width: 120px;
    padding: 0 16px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    white-space: nowrap;
    border: 1.5px solid;
    border-radius: 4px;

    &.sm {
      height: $input-size-sm;
    }
    &.md {
      height: $input-size-md;
    }
    &.lg {
      height: $input-size-lg;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .wrap {
      width: 10px;
      min-width: 10px;
      margin-left: 8px;
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .icon {
        width: 100%;
      }
    }
  }
  .options {
    position: absolute;
    bottom: 1px;
    transform: translateY(100%);
    background: #fff;
    width: 100%;
    z-index: 1;
    border: 1px solid;
    max-height: 200px;
    overflow: auto;

    .option {
      padding: 8px 16px;
      cursor: pointer;
      user-select: none;
    }
  }
}
</style>
