import apiFunc from '@/api/index'
import { ref, computed, onMounted } from 'vue'

const api = apiFunc()

export default function useBackup ({ enabled: enabledArg = true } = {}) {
  /* DATA */
  const extraData = ref({
    title: 'Резервное копирование',
    desc: 'Для резервного копирования, архивных данных, раздачи статического контента сайта, данных веб-приложений, систем видеонаблюдения, обмена корпоративными документами'
  })
  const items = ref([])
  const enabled = ref(enabledArg)
  const backupID = ref(null)

  /* COMPUTED */
  const total = computed(() => {
    if (!enabled.value) { return 0 }

    return items.value.reduce((acc, i) => {
      return acc + i.model * i.price
    }, 0)
  })

  const receipt = computed(() => {
    if (!enabled.value) { return [] }

    return [
      {
        title: extraData.value.title,
        options: items.value.map(i => {
          return {
            key: i.title,
            val: `${i.model} ${i.unit}`
          }
        })
      }
    ]
  })

  const requestBody = computed(() => {
    if (items.value.length === 0) { return null }
    if (!enabled.value) { return null }

    return {
      id: backupID.value,
      vm: items.value.find(i => i.id === 'vm').model,
      storage: items.value.find(i => i.id === 'storage').model
    }
  })

  /* METHODS */
  const fetchInfo = async () => {
    const { data } = await api.services.fetchBackup()

    backupID.value = data.id
    items.value = [
      {
        id: 'vm',
        title: 'Вирт. машины',
        unit: 'шт.',
        price: data.vmPrice,
        min: data.vmMinQuantity,
        max: data.vmMaxQuantity,
        step: data.vmStep,
        model: data.vmMinQuantity
      },
      {
        id: 'storage',
        title: 'Хранилище',
        unit: 'Гб.',
        price: data.storagePrice,
        min: data.storageMinQuantity,
        max: data.storageMaxQuantity,
        step: data.storageStep,
        model: data.storageMinQuantity
      }
    ]
  }

  onMounted(() => {
    try {
      fetchInfo()
    } catch (e) {
      console.log(e)
    }
  })

  return {
    enabled,
    receipt,
    total,
    requestBody,
    extraData,
    items
  }
}
