import apiFunc from '@/api/index'
import { ref, computed, onMounted } from 'vue'

const api = apiFunc()

export default function ({ enabled: enabledArg = true } = {}) {
  /* DATA */
  const extraData = ref({
    title: 'Облачное хранилище'
  })
  const list = ref([])
  const enabled = ref(enabledArg)

  /* METHODS */
  const fetchList = async () => {
    try {
      const res = await api.cloudStore.list()
      const items = res.data?.list ?? []

      list.value = items.map(item => ({
        id: item.id,
        min: item.min,
        max: item.max,
        current: item.min,
        step: item.step,
        enabled: true,
        price: item.price,
        label: item.label,
        unit: 'Гб'
      }))
    } catch (e) {
      console.log(e)
    }
  }

  /* COMPUTED */
  const price = computed(() => {
    if (!enabled.value) { return 0 }

    return list.value.reduce((acc, i) => {
      if (i.enabled) {
        return acc + i.price * i.current
      }

      return acc
    }, 0)
  })

  const receipt = computed(() => {
    if (!enabled.value) { return [] }

    const newArr = list.value.map(i => {
      if (i.enabled) {
        return {
          key: i.label,
          val: i.current + ' ' + i.unit
        }
      }
    })

    return [
      {
        title: extraData.value.title,
        options: newArr.filter(i => !!i)
      }
    ]
  })

  const requestBody = computed(() => {
    if (!enabled.value) { return null }

    const newArr = list.value.map(i => {
      if (i.enabled) {
        return {
          id: i.id,
          quantity: i.current
        }
      }
    })

    return newArr.filter(i => !!i)
  })

  onMounted(async () => {
    await fetchList()
  })

  return {
    extraData,
    enabled,
    requestBody,
    list,
    price,
    receipt,
    fetchList
  }
}
