<template>
  <input
    type="range"
    :disabled="disabled"
    :min="min"
    :max="max"
    :step="step"
    :value="modelValue"
    class="input-range"
    :class="[`input-range_${theme}`]"
    @input="onInput"
  >
</template>

<script>
export default {
  name: 'BaseInputRange',
  props: {
    disabled: Boolean,
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 0
    },
    modelValue: {
      type: Number,
      default: 0
    },
    theme: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'gold'].includes(val)
    }
  },
  emits: {
    'update:modelValue': null
  },
  computed: {
    getPercent () {
      return (this.modelValue - this.min) / (this.max - this.min) * 100 + '%'
    }
  },
  methods: {
    onInput (e) {
      const val = Number(e.target.value)
      this.$emit('update:modelValue', val)
    }
  }
}
</script>

<style lang="scss" scoped>
$inactive-color:                          #D3E0FF;
$primary-color:                           #3EBBEA !default;
$disabled-color:                          #ABABAB !default;
$dark-color:                              #333333 !default;
$indent:                                  v-bind(getPercent);

.input-range {
  width: 100%;
  display: block;
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
  border-radius: 1000px;

  &_primary {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(to right, $primary-color 0% $indent, $inactive-color $indent);
    }

    &::-webkit-slider-thumb {
      border: 2px solid $primary-color;
    }
  }

  &_gold {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(to right, $dark-color 0% $indent, $inactive-color $indent);
    }

    &::-webkit-slider-thumb {
      border: 2px solid $dark-color;
    }
  }

  &:disabled {
    &::-webkit-slider-runnable-track {
      background: linear-gradient(to right, $disabled-color 0% $indent, $inactive-color $indent);
    }

    &::-webkit-slider-thumb {
      border: 2px solid $disabled-color;
    }
  }

  &::-webkit-slider-runnable-track {
    border-radius: 1000px;
    height: 6px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    border-radius: 100%;
    background-color: #fff;
    height: 26px;
    width: 26px;
    margin-top: -10px;
  }
}
</style>
