<template>
  <div
    class="calc-item"
    :class="{ 'calc-item_disabled': !globalEnabled }"
  >
    <div class="calc-item__top">
      <BaseSwitches
        :modelValue="enabled"
        :label="label"
        :disabled="!globalEnabled"
        @update:modelValue="$emit('update:enabled', $event)"
      />
    </div>
    <div
      class="calc-item__bottom"
      :class="{ 'calc-item__bottom_local-disabled': !enabled }"
    >
      <div class="label">
        Объем
      </div>
      <div class="slider">
        <BaseInputRange
          :min="min"
          :max="max"
          :step="step"
          :modelValue="current"
          :disabled="!globalEnabled || !enabled"
          @update:modelValue="$emit('update:current', $event)"
        />
      </div>
      <p class="value">
        {{ current }} {{ unit }}
      </p>
    </div>
  </div>
</template>

<script>
import BaseSwitches from '@/components/Base/BaseSwitches'
import BaseInputRange from '@/components/Base/BaseInputRange'

export default {
  name: 'CalcItem',
  components: {
    BaseSwitches,
    BaseInputRange
  },
  props: {
    min: Number,
    max: Number,
    current: Number,
    step: Number,
    enabled: Boolean,
    label: [String, Number],
    unit: String,
    globalEnabled: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
$media-lg:                                1200px !default;
$media-md:                                992px !default;
$media-sm:                                768px !default;
$media-esm:                               576px !default;

.calc-item {
  &_disabled {
    color: #A6A6A6;

    .value {
      color: inherit !important;
    }
  }

  &__bottom {
    margin-top: 20px;
    display: grid;
    grid-template-columns: 100px 1fr 80px;
    grid-column-gap: 30px;

    @media screen and (max-width: $media-sm) {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }

    &_local-disabled {
      color: #A6A6A6;

      .value {
        color: inherit !important;
      }
    }

    .label {
    }

    .slider {
      @media screen and (max-width: $media-sm) {
        grid-row: 2 / 3;
        grid-column: 1 / 4;
      }
    }

    .value {
      color: #3EBBEA;
      font-weight: 600;
      text-align: right;

      @media screen and (max-width: $media-sm) {
        grid-column: 3 / 4;
      }
    }
  }
}
</style>
