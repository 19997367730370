<template>
  <label
    class="label"
    :class="{ label_disabled: disabled }"
  >
    <input
      type="checkbox"
      class="checkbox"
      :checked="checked"
      :value="value"
      @change="onChange"
    >

    <div
      class="switches"
      :class="[
        `switches_${theme}`,
        { switches_disabled: disabled }
      ]"
    />

    <template v-if="!label">
      <slot name="label" />
    </template>

    <span
      v-else
      v-text="label"
      class="title"
    />
  </label>
</template>

<script>
export default {
  name: 'BaseSwitches',
  props: {
    modelValue: {},
    value: {},
    label: String,
    disabled: Boolean,
    theme: {
      type: String,
      default: 'primary',
      validator: val => ['primary', 'gold'].includes(val)
    }
  },
  emits: {
    'update:modelValue': null
  },
  data () {
    return {
      localChecked: false
    }
  },
  computed: {
    isArray () {
      return Array.isArray(this.modelValue)
    },
    checked () {
      if (this.isArray) {
        return this.localChecked.includes(this.value)
      } else {
        return this.localChecked
      }
    }
  },
  watch: {
    modelValue: 'init'
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      if (this.isArray) {
        this.localChecked = this.modelValue.slice()
      } else {
        this.localChecked = !!this.modelValue
      }
    },
    onChange (event) {
      if (this.isArray) {
        const exists = this.localChecked.includes(this.value)

        if (exists) {
          this.localChecked = this.localChecked.filter(item => item !== this.value)
        } else {
          this.localChecked.push(this.value)
        }

        this.$emit('update:modelValue', this.localChecked)
      } else {
        this.$emit('update:modelValue', event.target.checked)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$primary-color:                           #3EBBEA !default;
$disabled-color:                          #ABABAB !default;
$dark-color:                              #333333 !default;

$switches-height: 31px;
$switches-width: $switches-height * 1.8;
$switches-indent: $switches-height * 0.1;
$switches-slider-size: $switches-height - $switches-indent - $switches-indent;

.label {
  display: inline-flex;
  align-items: center;
  padding: 2px;
  user-select: none;

  &_disabled {
    pointer-events: none;

    .title {
      color: rgba(120, 120, 128, 0.32) !important;
    }
  }

  .checkbox {
    display: none;
  }

  .checkbox:checked + .switches:before {
    left: $switches-width - $switches-indent - $switches-slider-size;
  }
  .checkbox:checked + .switches {
    &_primary {
      background: $primary-color;
    }

    &_gold {
      background: $dark-color;
    }

    &_disabled {
      background: $disabled-color !important;
    }
  }

  .switches {
    flex-shrink: 0;
    height: $switches-height;
    width: $switches-width;
    background: rgba(120, 120, 128, 0.32);
    border-radius: 1000px;
    position: relative;
    margin-right: 14px;

    &:before {
      content: '';
      transition: .2s;
      height: $switches-slider-size;
      width: $switches-slider-size;
      background: #fff;
      border-radius: 1000px;
      position: absolute;
      top: 50%;
      left: $switches-indent;
      transform: translate(0, -50%);
    }
  }

  .title {
    font-weight: 500;
    font-size: 16px;
  }
}
</style>
